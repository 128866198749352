import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";
import { AppDataService } from "../app-data.service";
import { LoggingHelper } from "../logging/helper";

@Injectable({ providedIn: "root" })
export class ErrorInterceptor extends LoggingHelper implements HttpInterceptor {
    // If the user has been notified about their session expiring
    private hasNotifiedExpiration = false;

    constructor(private appData: AppDataService, private router: Router) {
        super();
    }

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next
            .handle(request)
            .pipe(catchError((e) =>
                this.handleError(e)
            ));
    }

    handleError(err: HttpErrorResponse): Observable<never> {
        if (location.href.includes("dui")) return throwError(err);
        let msg = "An error occured; please try again later.";

        if (err.error instanceof ErrorEvent || err.error instanceof ProgressEvent) {
            this.logError("Client/net error: ", err.message);
            msg = "An internal error occured; please try again later or contact Development";
        } else {
            if (err.error) {
                if (err.status === 401) {
                    msg = "Unauthorized";

                    if (err.url && err.url.includes("auth")) {
                        this.appData.clear();
                    } else {
                        this.notifySessionExpiration();
                    }
                } else if ("message" in err.error) {
                    msg = `${err.error.message}`;
                } else if (err.status === 404) {
                    msg = "Not Found";
                } else {
                    msg = `${err.error}`;
                }
            }
        }

        return throwError(msg);
    }

    /**
     * Notify the user that their session has expired
     */
    notifySessionExpiration(): void {
        if (this.hasNotifiedExpiration) return;

        this.hasNotifiedExpiration = true;
        this.appData.clear();

        void this.router.navigateByUrl("/login");
        alert("Your session has expired. Please login again.");
    }
}
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CadPatrolService } from 'src/app/core/api/cad-patrol.service';
import { AppDataService } from 'src/app/core/app-data.service';
import { EDepartment, EPermission, Server } from 'src/app/core/models';

@Component({
  selector: 'osrp-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnDestroy {
  isRoot = false;
  isAdmin = false;
  isStaff = false;
  isLegal = false;
  shouldShowLivemap = false;
  isTablet = false;
  invalidSteamHex = false;
  isCivPrimary = false;

  canManageDiscord = false;

  servers: Server[];

  private _destroyed$ = new Subject<void>();

  constructor(private _appData: AppDataService, private _patrol: CadPatrolService, private _router: Router) {
    this._checkSteamId();

    this.isRoot = this._appData.isUserRoot();
    this.isAdmin = this._appData.isUserAdmin();
    this.isStaff = this._appData.isUserStaff();
    this.isLegal = this._appData.isUserLegal();

    this.canManageDiscord = this._appData.user.userPermissions.includes(EPermission.MANAGE_DISCORD);
    this.isCivPrimary = this._appData.user.userDepartments.find(u => u.isPrimary)?.departmentId === EDepartment.CIV;

    this.shouldShowLivemap = this.isAdmin;

    this.isTablet = this._appData.isTablet;

    this._patrol.getServersAsync().pipe(takeUntil(this._destroyed$)).subscribe(servers => {
      this.servers = servers;
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  logout(): void {
    this._appData.clear();
    void this._router.navigateByUrl("/login");
  }

  private _checkSteamId() {
    const whitelisted = this._appData.user.isWhitelisted || this._appData.user.isWhitelistedDev;
    const steamId = this._appData.user.steamId;

    if (whitelisted && (!steamId || steamId?.length === 0)) {
      this.invalidSteamHex = true;
      return;
    }

    if (steamId && ((steamId?.length !== 0 && steamId?.length !== 15) || !/^[0-9A-Fa-f]*$/.test(steamId))) {
      this.invalidSteamHex = true;
    }
  }
}

import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, filter, Subject, takeUntil, tap } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { AppDataService } from './core/app-data.service';
import { CanDeactivateState } from './core/auth/unload.guard';

@Component({
  selector: 'osrp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject<void>();
  @HostBinding("class") public cssClass = null;

  private isCadObs = new BehaviorSubject(false);
  private isDesktopSrc = new BehaviorSubject(false);
  isDesktop = this.isDesktopSrc.asObservable();

  constructor(private _router: Router, private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer, private _appData: AppDataService) {
    window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;

    this._router.events.pipe(filter((e) => e instanceof NavigationEnd), tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false), takeUntil(this._destroyed$)).subscribe();
  }

  ngOnInit() {
    this.subscribeToRouter();
    this._matIconRegistry.addSvgIcon("police", this._domSanitizer.bypassSecurityTrustResourceUrl("../../assets/police_icon.svg"));
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  subscribeToRouter(): void {
    this._router.events.pipe(takeUntil(this._destroyed$)).subscribe((event: RouterEvent) => {
      if (event && event instanceof NavigationEnd) {
        if (event.url && event.url.includes("/cad")) {
          this.isCadObs.next(true);
        } else {
          this.isCadObs.next(false);
        }

        this.isDesktopSrc.next(event.url && (event.url.includes("desktop") || event.url.includes("livemap")));
      }
    });
  }

  @HostListener("window:message", ["$event"])
  handleKeyDown(event: MessageEvent) {
    const data = event.data;

    if (typeof data !== "object" || data["type"] !== "token") return;
    if (this._appData.token) return;

    this._appData.token = data.token;
    this._router.navigateByUrl("/user/home");
  }

}

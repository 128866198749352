import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthAdminGuard } from './core/auth/admin.guard';
import { AuthCadGuard } from './core/auth/cad.guard';
import { LoginGuard } from './core/auth/login.guard';
import { AuthStaffGuard } from './core/auth/staff.guard';
import { AuthUserGuard } from './core/auth/user.guard';
import { AuthWarrantsGuard } from './core/auth/warrants.guard';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: "", redirectTo: "/user/home", pathMatch: "full" },
  { path: "404", component: PageNotFoundComponent },
  { path: "user", loadChildren: () => import("./user/user.module").then(m => m.UserModule), canActivate: [AuthUserGuard] },
  { path: "login", loadChildren: () => import("./login/login.module").then(m => m.LoginModule), canActivate: [LoginGuard] },
  { path: "cad", loadChildren: () => import("./cad/cad.module").then(m => m.CadModule), canActivate: [AuthUserGuard], canActivateChild: [AuthCadGuard] },
  { path: "livemap", loadChildren: () => import("./livemap/livemap.module").then(m => m.LivemapModule), canActivate: [AuthUserGuard], canActivateChild: [AuthCadGuard] },
  { path: "admin", loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule), canActivate: [AuthUserGuard], canActivateChild: [AuthAdminGuard] },
  { path: "staff", loadChildren: () => import("./staff/staff.module").then(m => m.StaffModule), canActivate: [AuthUserGuard], canActivateChild: [AuthStaffGuard] },
  { path: "discord", loadChildren: () => import("./discord/discord.module").then(m => m.DiscordModule), canActivate: [AuthUserGuard], canActivateChild: [AuthStaffGuard] },
  { path: "servers", loadChildren: () => import("./servers/servers.module").then(m => m.ServersModule), canActivate: [AuthUserGuard], canActivateChild: [AuthAdminGuard] },
  { path: "warrants", loadChildren: () => import("./warrants/warrants.module").then(m => m.WarrantsModule), canActivate: [AuthUserGuard], canActivateChild: [AuthWarrantsGuard] },
  { path: "dui", loadChildren: () => import("./dui/dui.module").then(m => m.DuiModule) },
  { path: "**", redirectTo: "/404" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

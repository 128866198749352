import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pad'
})
export class PadPipe implements PipeTransform {
  transform(
    value: string | number,
    amount: number = 5,
    fillString = '0',
  ): string {
    if (value == null) {
      return null;
    }

    return value.toString().padStart(amount, fillString);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.dev';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private apiUrl: string = environment.apiUrl + "/api";
  private apiKey: string = environment.apiKey;

  constructor(private httpClient: HttpClient, private _appData: AppDataService) { }

  buildHeaders(showSpinner = false): HttpHeaders {
    const token = this._appData.token;

    const headers = {
      "Content-Type": "application/json",
      "X-OSRP-APIKey": this.apiKey
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (!showSpinner) {
      headers["X-OSRP-No-Spinner"] = "Yes";
    }

    return new HttpHeaders(headers);
  }

  sendGetAsync<T = any>(path: string, showSpinner = true): Observable<HttpResponse<T>> {
    const headers = this.buildHeaders(showSpinner);
    return this.httpClient.get<T>(`${this.apiUrl}/${path}`, { headers: headers, observe: "response" }).pipe(catchError((err) => this.handleError(err as HttpErrorResponse)));
  }

  sendPostAsync<T = any>(path: string, body: any, showSpinner = true): Observable<HttpResponse<T>> {
    const headers = this.buildHeaders(showSpinner);
    return this.httpClient.post<T>(`${this.apiUrl}/${path}`, body, { headers: headers, observe: "response" }).pipe(catchError((err) => this.handleError(err as HttpErrorResponse)));
  }

  sendPutAsync<T = any>(path: string, body: any, showSpinner = true): Observable<HttpResponse<T>> {
    const headers = this.buildHeaders(showSpinner);
    return this.httpClient.put<T>(`${this.apiUrl}/${path}`, body, { headers: headers, observe: "response" }).pipe(catchError((err) => this.handleError(err as HttpErrorResponse)));
  }

  sendDeleteAsync<T = any>(path: string, showSpinner = true): Observable<HttpResponse<T>> {
    const headers = this.buildHeaders(showSpinner);
    return this.httpClient.delete<T>(`${this.apiUrl}/${path}`, { headers: headers, observe: "response" }).pipe(catchError((err) => this.handleError(err as HttpErrorResponse)));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errMsg: string = "An error occured; please try again later";
    if (error.error instanceof ErrorEvent) {
      errMsg = `An error occured: ${error.error.message}`;
    } else {
      if (error.status === 401) {
        if (error.url.includes("/api/auth")) {
          errMsg = `The email address and/or password you provided did not match our records`;
        }

        this._appData.clear();
      } else if (error.status === 403) {
        errMsg = `You do not have access to perform that action`;
      } else if (error.status === 404) {
        errMsg = `404 Not Found`;
      } else {
        errMsg = `${error.error}`;
      }
    }

    return throwError(errMsg);
  }
}

import { Injectable, Injector, OnDestroy } from "@angular/core";
import { BehaviorSubject, distinctUntilChanged, interval, map, Subject, takeUntil, Observable } from "rxjs";
import { environment } from "src/environments/environment.dev";
import { LoggingHelper } from "../logging/helper";
import { ISettingsSink } from "./models/ISettingsSink";
import { IdbSettingsSink } from "./sinks/idb-settings-sink";
import { LocalSettingsSink } from "./sinks/local-settings-sink";

@Injectable({
    providedIn: "root"
})
export class SettingsService extends LoggingHelper implements OnDestroy {
    /**
     * All sinks that will be used to load/save settings
     * index 0 is saved to first and loaded from last
     */

    private hasLoaded = false;
    private sinks: ISettingsSink[];
    private settings: Record<string, any> = {};
    private destroyed$ = new Subject();
    private currentSettingsSrc$ = new BehaviorSubject<Record<string, any>>({});
    currentSettings$ = this.currentSettingsSrc$.asObservable();

    constructor() {
        super();

        if (!IDBTransaction) {
            this.logWarning(`Cannot detect IDB browser support, falling back to local storage...`);
            // this.sinks = [new LocalSettingsSink()];
        } else {
            // this.sinks = [new IdbSettingsSink()];
        }

        if (!environment.production) {
            window["BcrpServices"] = { ...window["BcrpServices"], SettingsService: this };
        }

        // Load from all sinks
        // this.loadFromAll();

        // Save to all sinks every five (5) minutes (starting in 5 minutes)
        // interval(1000 * 60 * 2).pipe(takeUntil(this.destroyed$)).subscribe((_) => this.saveToAll());
    }

    ngOnDestroy(): void {
        // Prevent a memory leak by destroying the auto-save timer and triggering a manual save
        this.destroyed$.next(true);
        this.saveToAll();

        if (window["BcrpServices"]?.["SettingsService"] === this) {
            delete window["BcrpServices"]["SettingsService"];
        }
    }

    /**
     * Sets `key` to `value` in the local settings store
     */
    setSetting(key: string, value: any) {
        if (typeof key !== "string") {
            this.logError(`Cannot set setting, ${key} is not a string`);
            return;
        }

        this.settings[key] = value;
        this.currentSettingsSrc$.next(this.settings);
    }

    /**
     * Retrieves a value from the setting store, or undefined if it doesn't exisxt
     */
    getSetting<T>(key: string): T | undefined;

    /**
     * Retrieves a value from the setting store, or `defaultValue` if it does not exist
     */
    getSetting<T>(key: string, defaultValue: T): T;

    getSetting<T>(key: string, defaultValue?: T): T | undefined {
        if (this.settings[key]) {
            return this.settings[key] as T;
        } else if (defaultValue) {
            this.setDefaultValue(key, defaultValue);
            return defaultValue as T;
        } else {
            return undefined;
        }
    }

    /**
     * Listen to updates to a setting value
     */
    listenToSetting<T>(key: string): Observable<T | undefined> {
        return this.currentSettings$.pipe(map((settings) => settings[key] as T), distinctUntilChanged());
    }

    /**
     * Sets `key` to `defaultValue` if it is currently not stored, otherwise it keeps it at current value
     */
    setDefaultValue(key: string, defaultValue: any) {
        if (!this.settings[key]) {
            this.setSetting(key, defaultValue);
        }
    }

    /**
     * Saves the settings to all sinks (to disk/API), starting at lowest index
     */
    saveToAll() {
        for (let i = 0; i < this.sinks.length; i++) {
            this.saveToSink(i);
        }
    }

    /**
     * Saves settings to sink at `index`
     */
    private saveToSink(index: number) {
        const sink = this.sinks[index];
        if (sink) {
            // sink.save(this.settings);
        } else {
            this.logWarning(`Cannot save setting sink index ${index} as it doesn't exist`);
        }
    }

    /**
     * Loads settings from all sinks, starting at highest index
     */
    loadFromAll() {
        for (let i = this.sinks.length - 1; i >= 0; i--) {
            this.loadFromSink(i);
        }
    }

    /**
     * Loads settings from sink at `index`
     */
    private loadFromSink(index: number) {
        const sink = this.sinks[index];
        if (sink) {
            // const loaded = sink.load();
            // if (loaded instanceof Promise) {
            //     void loaded.then((setting) => this.applyLoadedSettings(setting));
            // } else {
            //     this.applyLoadedSettings(loaded);
            // }
        } else {
            this.logWarning(`Couldn't load from settings sink index ${index} as it does not exist`);
        }
    }

    /**
     * Applies when a given setting object to the cached settings in memory
     */
    private applyLoadedSettings(newSettings: Record<string, any>) {
        this.settings = { ...this.settings, ...newSettings };
        this.currentSettingsSrc$.next(this.settings);
    }
}
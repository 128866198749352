import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthWarrantsGuard implements CanActivateChild {
  constructor(private _appData: AppDataService, private _router: Router) { }

  canActivateChild(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (this._appData.isUserLegal()) {
        return true;
    } else {
        return this._router.parseUrl(`/user/home`);
    }
  }
}

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ExistingProvider } from "@angular/core";
import { AuthInterceptor } from "./auth.interceptor";
import { ErrorInterceptor } from "./error.interceptor";
import { SpinnerInterceptor } from "./spinner.interceptor";

export const httpInterceptors: ExistingProvider[] = [
    {
        provide: HTTP_INTERCEPTORS,
        useExisting: AuthInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useExisting: ErrorInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useExisting: SpinnerInterceptor,
        multi: true
    }
]
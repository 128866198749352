import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { UsersService } from '../api/users.service';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserGuard implements CanActivate {
  constructor(private data: AppDataService, private _router: Router, private _user: UsersService) { }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    const user = this.data.user;

    if (!this.data.token) {
      // no token => go to login page
      return this.getLoginRedirect(_state);
    } else if (!user) {
      // no user, but there is a jwt token => get user from token
      return this._user.getCurrentUser().pipe(map((u) => {
        if (!u) {
          // no user => redirect to login
          return this.getLoginRedirect(_state);
        } else {
          // user exists and has permissions => allow access
          return true;
        }
      }), catchError((_err: unknown) => of(this.getLoginRedirect(_state))), retry(2));
    }

    return true;

    // return this._user.getCurrentUser().pipe(catchError((err) => {
    //   console.log(err);
    //   return of(null);
    // }), map(user => {
    //   if (user != null) {
    //     // logged in
    //     return true;
    //   } else {
    //     // not logged in so redirect to login page with return url
    //     void this._router.navigate(["/login"], { queryParams: { returnUrl: _state.url } });
    //     return false;
    //   }
    // }));
  }
  

  private getLoginRedirect(state: RouterStateSnapshot): UrlTree {
    return this._router.parseUrl(`/login?returnUrl=${state.url}`);
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, length?: number): string {
    const limit = isNaN(length) ? 20 : length;
    const trail = '…';
    return value.length > limit ? value.substring(0, limit).trim() + trail : value;
  }

}

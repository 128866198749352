import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivateChild {
  constructor(private _router: Router, private _appData: AppDataService) { }

  canActivateChild(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree {
    if (this._appData.isUserAdmin()) {
      return true;
    } else {
      return this._router.parseUrl("/user/home");
    }
  }
}
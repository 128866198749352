//
// Enums
//

export enum AudioSound {
    newCallAssigned,
    pingUnit,
    activeWarrants,
    expiredLicense,
    revokedLicense,
    suspendedLicense,
    stolenVehicle,
}

export enum BeatArea {
    Los_Santos = "Los Santos",
    Blaine_County = "Blaine County",
    Both = "Both"
}

export enum CharacterLicenseType {
    DRIVERS = "DRIVERS",
    CCW = "CCW",
    CDL = "CDL"
}

export enum EDepartment {
    LSPD = 1,
    BCSO = 2,
    SAHP = 3,
    COMM = 4,
    FIRE = 5,
    CIV = 6
}

export enum EPermission {
    MANAGE_USERS = 'MANAGE_USERS', // user management from admin pages
    MANAGE_MEMBERS = 'MANAGE_MEMBERS', // user management from staff pages
    MANAGE_SERVERS = 'MANAGE_SERVERS', // for server management (log files, restart, etc)
    MANAGE_DISCORD = 'MANAGE_DISCORD', // manage stuff like embeds
    DISCORD_ADMIN = 'DISCORD_ADMIN' // setup channels for links, announcements, etc
}

export enum EUnitClass {
    Other = 0,
    Leo = 1,
    Comms = 2,
    Fire = 3,
}

export enum EUnitStatus {
    offDuty = 1,
    outOfService = 2,
    inService = 3,
    busy = 4,
    enRoute = 5,
    onScene = 6,
    subjectInCustody = 7
}

export enum EUnitType {
    Patrol = 1,
    Air = 2,
    Dispatcher = 3
}

export enum EOffenceType {
    Infraction = "Infraction",
    Misdemeanor = "Misdemeanor",
    Felony = "Felony"
}

export enum DiscordChannelType {
    Text = "Text",
    MemberLogs = "MemberLogs",
    GeneralLogs = "GeneralLogs",
    CommunityAnnouncements = "CommunityAnnouncements",
    TicketsCategory = "TicketsCategory",
    Link = "Link",
    Voting = "Voting",
    Ridealong = "Ridealong",
    TempPass = "TempPass",
    CoCBotInfo = "CoCBotInfo"
}

export enum DiscordRoleType {
    Other = "OTHER",
    Guest = "GUEST",
    Reserve = "RESERVE",
    PatrolNotify = "PATROL_NOTIFY",

    // Community Ranks
    Recruit = "RECRUIT",
    Member = "MEMBER",
    SiT = "SIT",
    Staff = "STAFF",
    SeniorStaff = "SENIOR_STAFF",
    Admin = "ADMIN",

    // Primary Departments
    Police = "POLICE",
    Sheriff = "SHERIFF",
    Highway = "HIGHWAY",
    Communications = "COMMS",
    Civilian = "CIV",

    // Tertiary Departments
    Media = "MEDIA",
    FTO = "FTO",
    Dev = "DEV",
}

export enum LogLevel {
    Error = 500,
    Warning = 400,
    Info = 300,
    Debug = 200
}

//
// Helper Types
//

export type DiscordRoleType_CommunityRank =
    | DiscordRoleType.Recruit
    | DiscordRoleType.Member
    | DiscordRoleType.SiT
    | DiscordRoleType.Staff
    | DiscordRoleType.SeniorStaff
    | DiscordRoleType.Admin;

export type DiscordRoleType_Department =
    | DiscordRoleType.Police
    | DiscordRoleType.Sheriff
    | DiscordRoleType.Highway
    | DiscordRoleType.Civilian
    | DiscordRoleType.Communications;

export type DiscordRoleType_Misc =
    | DiscordRoleType.Media
    | DiscordRoleType.FTO
    | DiscordRoleType.Dev;

export type ExtendedGuild = DiscordGuild & { extension: GuildInfoExtension };

export type LoggableType =
    | string
    | number
    | bigint
    | boolean
    | undefined
    | null;

//
// Raw Interfaces
//

export interface GuildInfoExtension {
    roles: {
        id: string;
        name: string;
        position: number;
        color: string;
    }[];

    channels: {
        id: string;
        name: string;
        position: number;
        type: "GUILD_TEXT" | "GUILD_CATEGORY";
        parent: {
            id: string;
            name: string;
            position: number;
        } | null;
    }[];
}

//
// Models
//

export interface Community {
    communityId: number;
    name: string;
    tagline: string;
}

export interface ValueChanged {
    value?: any;
    oldValue?: any;
}

export interface CadPenalTitle {
    titleNumber: number;
    titleName: string;
    offences: CadPenalCode[];
}

export interface CadPenalCode {
    offenceId: number;
    offenceType: EOffenceType;
    section: string;
    description: string;
    notes: string;
    jailTime: number;
    fineAmount: number;
    titleNumber: number;
    isDeleted: boolean;
}

export interface PatrolArrest {
    arrestId: number;
    characterId: number;
    officerUserId: number;
    departmentId: number;
    vehicleId?: number;

    dateCharged: Date;

    location: string;
    notes?: string;

    isDeleted: boolean;

    officerUser?: User;
    department: Department;
    charges: ArrestCharge[];
}

export interface PatrolCitation {
    citationId: number;
    characterId: number;
    officerUserId: number;
    departmentId: number;
    vehicleId?: number;

    dateCharged: Date;

    totalAmount: string;
    location: string;
    notes?: string;
    vehicleDescription: string;

    isPaid: boolean;
    isDeleted: boolean;

    officerUser?: User;
    department: Department;
    charges: CitationCharge[];
}

export interface PatrolWarning {
    warningId: number;
    characterId: number;
    officerUserId: number;
    departmentId: number;
    vehicleId?: number;

    dateIssued: Date;

    location: string;
    notes?: string;

    isDeleted: boolean;

    officerUser?: User;
    department: Department;
    charges: WarningCharge[];
}

export interface ArrestCharge {
    chargeId: number;
    arrestId: number;
    offenceId: number;
    offence: CadPenalCode;
}

export interface CitationCharge {
    chargeId: number;
    citationId: number;
    offenceId: number;
    offence: CadPenalCode;
}

export interface WarningCharge {
    chargeId: number;
    warningId: number;
    offenceId: number;
    offence: CadPenalCode;
}

export interface BankAccount {
    accountId: number;
    character: Character;
    characterId: number;
    balance: number;
    name: string;
    bank: string;
    isDeleted: boolean;
}

export interface Character {
    characterId: number;
    cashOnHand: number;
    dateOfBirth: Date;
    createdAt: Date;
    lastUsedAt: Date;
    firstName: string;
    lastName: string;
    gender: string;
    department: Department;
    departmentId: number;
    user: User;
    userId: number;
    bankAccounts: BankAccount[];
    licenses: CharacterLicense[];
    vehicles: Vehicle[];
    arrests: PatrolArrest[];
    warnings: PatrolWarning[];
    citations: PatrolCitation[];
    isDeleted: boolean;
    isDeceased: boolean;
    bankAccountsFrozen: boolean;
}

export interface CharacterLicense {
    licenseId: number;
    licenseType: CharacterLicenseType;
    isRevoked: boolean;
    isSuspended: boolean;
    issuedAt: Date;
    expiresAt?: Date;
    createdAt: Date;
    character?: Character;
    characterId: number;
}

export interface Vehicle {
    vehicleId: number;
    licensePlate: string;
    modelName: string;
    color: string;
    destroyedAt?: Date;
    impoundedAt?: Date;
    createdAt: Date;
    expiresAt?: Date;
    characterId: number;
    registration: any[];
    isStolen: boolean;
    isDeleted: boolean;
}

export interface Server {
    serverId: number;
    address: string;
    port: number;
    name: string;
    serviceName: string;
    isInCad: boolean;
    isDevServer: boolean;
    weather?: string;
    gameHour?: number;
    gameMinute?: number;
    areaOfPatrol: string;
    cadPriority: boolean;
    cadChannelHeld: boolean;
    containerId: string;
}

export interface ServerPlayer {
    id: number;
    endpoint: string;
    identifiers: string[];
    name: string;
    ping: number;
}

export interface ServerMetaData {
    playerList: ServerPlayer[];
    maxPlayers: number;
}

export interface ServerData {
    server: Server;
    metadata: ServerMetaData;
}

export interface ResponseDto {
    ok: boolean;
    payload?: string;
    error?: string;
}

export interface Department {
    departmentId: number;
    name: string;
    shortName: string;
    type: number;
    isCadEnabled: boolean;
    isInGame: boolean;
    isReserve: boolean;
    isIdentifierInherited: boolean;
}

export interface DepartmentRank {
    rankId: number;
    rankName: string;
    rankNameShort: string;
    isReserveRank: boolean;
    departmentId: number;
    sortId: number;
    salary: number;
    department: Department;
}

export interface PatrolBolo {
    boloId: number;
    displayNumber?: string;
    displayNumberVersioned?: string;
    vehicleDescription?: string;
    personDescription?: string;
    location?: string;
    officer: string;
    reason: string;

    createdByUserInfo: User;
    createdById: number;

    modifiedByUserInfo: User;
    modifiedById: number;

    serverId: number;

    createdAt: Date;
    modifiedAt?: Date;

    clearedAt?: Date;
    isActive: boolean;
}

export interface PatrolCallLog {
    callLogId: number;
    message: string;
    postedByUserId: number;
    postedByUnitNumber: string;
    createdAt: string;
    callId: number;
}

export interface PatrolCallType {
    callTypeId: number;
    name: string;
    description: string;
}

export interface PatrolCall {
    callId: number;
    location: string;
    postal: string;
    description: string;
    sharedNotepad?: string;
    weaponStatus?: string;
    caller?: string;
    codeFour: boolean;
    isPriorityCall: boolean;
    x?: number;
    y?: number;
    gameGenerated: boolean;
    createdAt: Date;
    clearedAt?: Date;
    serverId: number;
    callTypeId: number;
    callType?: PatrolCallType;
    attachedUnits: PatrolUnit[];
    callLogs?: PatrolCallLog[];
    isActive: boolean;
    isDeleted: boolean;
    server?: Server;
}

export interface UpdateCallDto {
    location?: string;
    postal?: number;
    description?: string;
    isPriorityCall?: boolean;
    x?: number;
    y?: number;
}

export interface PatrolUnit {
    userId: number;
    user: User;
    oldIdentifier?: string;
    identifier: string;
    unitStatus: EUnitStatus;
    unitClass?: EUnitClass;
    unitType: EUnitType;
    connected: boolean;
    beatId?: number;
    beat?: Beat;
    subdivisonId?: number;
    availableSubdivisions?: string;
    districtId?: number;
    serverId: number;
    server: Server;
    currentCallId?: number;
    currentCall: PatrolCall;
    name: string;
    departmentId: number;
    department: Department;
}

export interface Beat {
    beatId: number;
    departmentId: number;
    description: string;
    shortName: string;
    area: BeatArea;
}

export interface Role {
    roleId: number;
    userId: number;
    user: User;
    roleGroupId: number;
    roleGroup: RoleGroup;
}

export interface RolePermission {
    rolePermissionId: number;
    flag: EPermission;
    roleGroupId: number;
    roleGroup: RoleGroup;
}

export interface RoleGroup {
    roleGroupId: number;
    name: string;
    immunity: number;
    isTemp: boolean;
    rolePermissions: RolePermission[];
    users: User[];
}

export interface UserDataDto {
    user: User;
    password: string;
    identifier?: string;
    deptId?: number;
    rankId?: number;
    isExistingUser: boolean;
    updateDepartments: boolean;
}

export interface IpsUser {
    id: number;
    name: string;
    email: string;
    steamHex: string;
    primaryGroup: IpsGroup;
    secondaryGroups: IpsGroup[];
}

export interface IpsGroup {
    id: number;
    name: string;
}

export interface User {
    userId: number;
    websiteId: number;
    name: string;
    email: string;
    ts3Id?: string;
    steamId: string;
    discordId: string;
    isWhitelisted: boolean;
    isWhitelistedDev: boolean;
    isDeleted: boolean;
    createdAt: Date;
    characters: Character[];
    userDepartments: UserDepartment[];
    roles: Role[];
    isEnabled: boolean;
    highestImmunityLevel: number;
    userPermissions?: EPermission[];
}

export interface UserDepartment {
    userDepartmentId: number;
    identifier: string;
    isPrimary: boolean;
    department: Department;
    departmentId: number;
    rank: DepartmentRank;
    rankId: number;
    userId: number;
    user: User;
}

export interface LogEvent {
    eventId: number;
    source: string;
    logLevel: LogLevel;
    loggedAt: Date;
    message: LoggableType[];
}

export interface SearchEventsDto {
    source?: string;
    logLevel?: LogLevel;
    from: Date;
    to: Date;
    contains?: string;
}

export interface PlayerUpdate {
    x: number;
    y: number;
    z: number;
    heading: number;
    inVehicle: boolean;
    vehicleName: string;
    vehiclePlate: string;
    vehicleClass: number;
    streetName: string;
    currentName: string;
    serverId: number;
    userId: number;
    departmentId: number;
    userName: string;
    isSupervisor: boolean;
}

export interface LivemapUpdateDto {
    players: PlayerUpdate[];
    weather?: string;
    hours: number;
    minutes: number;
}

export interface WorldUpdateDto {
    weather?: string;
    hours: number;
    minutes: number;
}

export interface PriorityStatusResult {
    priorityOwnerName: string;
    timerExpiresAt: number;
    holdExpiresAt: number;
}

export interface WarrantCreateDto {
    warrantTypeId: number;
    characterId: number;
    charges: string;
    warrantEvidence: string;
    requestingOfficer: string;
    requestingDepartmentId?: number | undefined;
    location: string;
    locationPossessor: string;
    locationTypeId?: number | undefined;
    suspectedEvidence: string;
    caseFile?: string;
}

export interface Warrant {
    warrantId: number;
    warrantTypeId: number;
    displayNumber: string;
    warrantStatus: string;
    createdAt: string;
    approvedAt: string;
    characterId: number;
    charges: string;
    warrantEvidence: string;
    requestingOfficer: string;
    requestingDepartmentId?: number | undefined;
    location: string;
    locationPossessor: string;
    locationTypeId?: number | undefined;
    suspectedEvidence: string;
    caseFile: string;
    isActive: boolean;
    isServed: boolean;
    locationType: WarrantLocationType;

    approvedByUserInfo: User;
    warrantType: WarrantType;
    requestingDepartment: Department;
    character: Character;
}

export interface WarrantLocationType {
    warrantLocationTypeId: number;
    title: string;
}

export interface WarrantType {
    warrantTypeId: number;
    title: string;
}

export interface CreateChannelDto {
    name: string;
    channelId: string;
    guildId: string;
}

export interface UpdateChannelDto {
    name?: string;
    channelId?: string;
    type?: DiscordChannelType;
    ridealongPingId?: string | null;
    ridealongOfferPingId?: string | null;
}

export interface UpdateLinksDto {
    linkGroups?: DiscordLinkGroup[];
}

export interface UpdateRoleControllablesDto {
    controllableIds: string[];
}

export interface UpdateRoleDto {
    guildId: string;
    roleType: DiscordRoleType;
}

export interface DiscordGuild {
    guildId: string;
    name: string;
    departmentId: number;
    department: Department;
    verifyFailover: string;
    channels: DiscordChannel[];
    roles: DiscordRole[];
}

export interface DiscordChannel {
    channelId: string;
    name: string;
    type: DiscordChannelType;
    ridealongPingId?: string;
    ridealongPing?: DiscordRole;
    ridealongOfferPingId?: string;
    ridealongOfferPing?: DiscordRole;
    guildId: string;
    guild: DiscordGuild;
    linkGroups: DiscordLinkGroup[];
}

export interface DiscordRole {
    roleId: string;
    roleType?: DiscordRoleType;
    controllers: DiscordRole[];
    controlables: DiscordRole[];
    guild: DiscordGuild;
    guildId: string;
}

export interface DiscordLinkItem {
    linkItemId: number;
    label: string;
    link: string;
    linkGroupId: number;
    linkGroup: DiscordLinkGroup;
}

export interface DiscordLinkGroup {
    linkGroupId: number;
    order: number;
    color: string;
    title: string;
    channelId: string;
    channel: DiscordChannel;
    links: DiscordLinkItem[];
}

export interface Address {
    addressId: number;
    addressDescription: string;
    coordinateId: number;
    coordinate: Coordinate;
}

export interface Coordinate {
    coordinateId: number;
    description: string;
    heading: number;
    x: number;
    y: number;
    z: number;
}

export interface PropertyOwnership {
    ownershipId: number;
    propertyId: number;
    characterId: number;
    isDeleted: boolean;
    character: Character;
    property: Property;
}

export interface PropertyType {
    propertyTypeId: number;
    propertyTypeName: string;
}

export interface Property {
    propertyId: number;
    propertyName: string;
    addressId: number;
    propertyHasIPL: boolean;
    propertyInteriorCoordinateId: number;
    propertyIPL: string;
    propertyTypeId: number;
    propertyPrice: number;
    isDeleted: boolean;
    address: Address;
    coordinate: Coordinate;
    propertyType: PropertyType;
}

export interface ServerGroup {
    sgid: string;
    name: string;
    addPerm?: number;
    nAddPerm: number;
    removePerm?: number;
    nRemovePerm: number;
}

export interface ServerGroupReturnDto {
    client: ServerGroup[];
    server: ServerGroup[];
    removePerm: number;
}

export interface StaffUpdateDto {
    name: string;
    userId: number;
    rankId: number;
    websiteId: number;
    departmentId: number;
    identifier: string;
}
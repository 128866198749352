import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/core/models';

@Pipe({
  name: 'lastUsedFramework'
})
export class LastUsedFrameworkPipe implements PipeTransform {
  transform(user: User) {
    if (user.characters.length < 1) return null;

    const characters = user.characters.filter(x => x.lastUsedAt != null);
    if (characters.length < 1) return null;

    return new Date(Math.max(...characters.map((c) => new Date(c.lastUsedAt).getTime())));
  }
}

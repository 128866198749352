import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { UsersService } from 'src/app/core/api/users.service';
import { LoggingHelper } from 'src/app/core/logging/helper';
import { Department } from 'src/app/core/models';

@Component({
  selector: 'osrp-dropdown-dept',
  templateUrl: './dropdown-dept.component.html',
  styleUrls: ['./dropdown-dept.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: "ngModel"
})
export class DropdownDeptComponent extends LoggingHelper implements OnInit, OnDestroy {
  depts: Department[] = [];
  @Input() department: Department = null;

  @Output() departmentChanged = new EventEmitter<Department>();
  @Output() valid: boolean = false;

  @Input() selectionRequired: boolean = true;
  @Input() showAllDepts: boolean = false;
  @Input() disable: boolean = false;
  @Input() exclude: number[] = [];
  @Input() dropdownClass: string[] = [];

  private _destroyed$ = new Subject<void>();

  constructor(private _userService: UsersService) { super(); }

  ngOnInit() {
    this._userService.getDepartmentsAsync(this.showAllDepts).pipe(takeUntil(this._destroyed$)).subscribe(depts => {
      this.depts = depts.filter(d => !this.exclude.includes(d.departmentId));
    }, (err) => {
      this.logError(err);
      this.depts = [];
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  setDepartment(value: Department) {
    this.department = value;
    this._updateValidation();
    this.departmentChanged.emit(this.department);
  }

  isValueNotNull() {
    return (this.department != null);
  }

  onDepartmentChanged(deptId: number): void {
    this.department = this.depts.find(d => d.departmentId === deptId);
    this._updateValidation();
    this.departmentChanged.emit(this.department);
  }

  private _updateValidation() {
    this.valid = !!this.department;
  }
}

<footer [class.hidden]="!showFooter" class="mat-typography">
    <mat-divider></mat-divider>
    <h4>&copy; Copyright 2023 OceanstateRP
        <br>
        <span *ngIf="showAdvancedOptions">
            <a href="javascript:;" (click)="exportLogs()">Download Logs</a>
            &nbsp;
            <a href="javascript:;" (click)="toggleTabletMode()">{{ isTablet ? 'Disable Tablet Mode' : 'Enable Tablet Mode' }}</a>
        </span>
    </h4>
</footer>

<!-- <footer>&copy; Copyright 2023 OceanstateRP Community</footer> -->
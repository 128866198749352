import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DepartmentRank } from 'src/app/core/models';

@Component({
  selector: 'osrp-dropdown-rank',
  templateUrl: './dropdown-rank.component.html',
  styleUrls: ['./dropdown-rank.component.scss']
})
export class DropdownRankComponent {
  @Input() ranks: DepartmentRank[] = [];
  @Input() rank: DepartmentRank = null;
  
  @Output() rankChanged = new EventEmitter<DepartmentRank>();
  @Output() valid: boolean = false;

  @Input() selectionRequired: boolean = true;

  constructor() { }

  onRankChanged(rankId: number): void {
    this.rank = this.ranks.find(r => r.rankId === rankId);
    this._updateValidation();
    this.rankChanged.emit(this.rank);
  }

  setRank(r: DepartmentRank): void {
    this.rank = r;
    this._updateValidation();
    this.rankChanged.emit(this.rank);
  }

  private _updateValidation() {
    this.valid = !!this.rank;
  }
}

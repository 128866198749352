<nav class="navbar-header mat-elevation-z6">
    <h1 class="main-title" routerLink="/user/home">Oceanstate Roleplay</h1>
    <span class="menu-spacer"></span>
    <a mat-button routerLink="/user/home">Dashboard</a>
    <a mat-button routerLink="/user/patrol">Patrol</a>

    <a *ngIf="isLegal" mat-button routerLink="/warrants">Manage Warrants</a>

    <a mat-button href="javascript:;" [matMenuTriggerFor]="lmMenu" *ngIf="shouldShowLivemap && !isTablet">Livemap <mat-icon>arrow_drop_down</mat-icon></a>
    <mat-menu #lmMenu="matMenu">
        <button *ngFor="let server of servers" mat-menu-item routerLink="/livemap" [queryParams]="{ serverId: server.serverId }">{{ server.name }}</button>
    </mat-menu>

    <a mat-button href="javascript:;" [matMenuTriggerFor]="staffMenu" *ngIf="isStaff">Staff <mat-icon>arrow_drop_down</mat-icon></a>
    <mat-menu #staffMenu="matMenu">
        <button mat-menu-item routerLink="/staff/members">Manage Members</button>
        <button mat-menu-item routerLink="/discord/list">Manage Discord</button>
        <!-- <button mat-menu-item routerLink="/staff/priority" *ngIf="isCivPrimary || isAdmin">Manage Priorities</button> -->
    </mat-menu>

    <a mat-button href="javascript:;" [matMenuTriggerFor]="adminMenu" *ngIf="isAdmin">Admin <mat-icon>arrow_drop_down</mat-icon></a>
    <mat-menu #adminMenu="matMenu">
        <button mat-menu-item routerLink="/admin/add-user">Add User</button>
        <button mat-menu-item routerLink="/admin/users">Manage Users</button>
        <button mat-menu-item routerLink="/servers/list">Manage Servers</button>
        <button mat-menu-item routerLink="/servers/events">API Logs</button>
    </mat-menu>

    <div class="navbar-actions">
        <a mat-button routerLink="/user/settings" matTooltip="Account Settings"><mat-icon>settings</mat-icon></a>
        <a mat-button (click)="logout()" matTooltip="Logout"><mat-icon>exit_to_app</mat-icon></a>
    </div>
</nav>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment.dev";
import { AppDataService } from "../app-data.service";

@Injectable({ providedIn: "root" })
export class AuthInterceptor implements HttpInterceptor {
    constructor(private appData: AppDataService) { }

    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        let headers = req.headers.set(`X-OSRP-APIKey`, environment.apiKey);

        if (this.appData.token) {
            headers = headers.set(`Authorization`, `Bearer ${this.appData.token}`);
        }

        const authReq = req.clone({ headers });
        return next.handle(authReq);
    }
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { IDBPDatabase, IDBPTransaction, openDB } from 'idb';
import { ELogLevel } from './ELogLevel.enum';
import { environment } from 'src/environments/environment.dev';
import { ILogEntry } from './ILogEntry';

const DB_NAME = "logging";
const LOGS_STORE_NAME = "logs";
const MAX_LOGS = 150;

@Injectable({
  providedIn: 'root'
})
export class LoggingService implements OnDestroy {
  static instance: LoggingService;

  /**
   * If the user has been notified that there is not a proper instance
   */
  static loggedInvalidInit = false;

  private _db: IDBPDatabase;
  private _dbInit = false;
  private _preDbLogs: ILogEntry[] = [];

  constructor(private httpClient: HttpClient) {
    if (!LoggingService.instance) {
      LoggingService.instance = this;
    } else {
      this.log(this.constructor.name, ELogLevel.Warning, "Multiple instances of \"Logging Service\" were created");
      LoggingService.instance = this;
    }

    // Expose to browser for debugging
    if (!environment.production) {
      window["BcrpServices"] = { ...window["BcrpServices"], LoggingService: LoggingService.instance };
    }

    // Expose advanced exports to windows during debugging and production to allow usage in debugging problems in live
    window["bcrpExportLogsAdvanced"] = this.exportLogsAdvanced.bind(this);
    window["bcrpDownloadLogsAdvanced"] = this.downloadLogsAdvanced.bind(this);

    void this._initDatabase();
  }

  ngOnDestroy() {
    if (LoggingService.instance === this) {
      LoggingService.instance = null;
    }

    if (window["BcrpServices"]?.["LoggingService"] === this) {
      delete window["BcrpServices"]["LoggingService"];
    }
  }

  /**
   * Logs `args` as an error coming from `source`
   */
  error(source: string, ...args: any[]) {
    this.log(source, ELogLevel.Error, ...args);
  }

  /**
   * Logs `args` as a warning coming from `source`
   */
  warning(source: string, ...args: any[]) {
    this.log(source, ELogLevel.Warning, ...args);
  }

  /**
   * Logs `args` as an informational log coming from `source`
   */
  info(source: string, ...args: any[]) {
    this.log(source, ELogLevel.Info, ...args);
  }

  /**
   * Logs `args` as debug information coming from `source`
   */
  debug(source: string, ...args: any[]) {
    this.log(source, ELogLevel.Debug, ...args);
  }

  /**
   * Logs data into the console and database
   */
  log(source: string, level: ELogLevel, ...args: any[]) {
    if (!source) {
      source = "Unknown";
    }

    const consoleOutput = this._getConsoleForLevel(level);
    if (consoleOutput) {
      const styling = this._getConsoleStyling(level);
      if (styling) {
        consoleOutput(`%c[${source}] `, styling, ...args);
      } else {
        consoleOutput(`[${source}] `, ...args);
      }
    }

    const entry: ILogEntry = {
      source: source,
      timestamp: new Date(),
      level: level,
      args: args
    };

    // If the database has been initialized store the log entry there, otherwise store for entry later
    if (this._dbInit) {
      void this._storeEntry(entry);
    } else {
      this._preDbLogs.push(entry);
    }
  }

  /**
   * Exports the logs to a text format
   */
  async exportLogs(num = -1): Promise<string> {
    // Exclude debug logs to prevent the log from being unreadable
    const entries = (await this._getEntries(num)).filter(entry => entry.level !== ELogLevel.Debug);
    return entries.map((entry) => `[${entry.timestamp.toISOString()}] [${this.getLevelName(entry.level)}/${entry.source}] ${entry.args.map((arg) => this._stringify(arg)).join(" ")}`).join("\n");
  }

  async exportLogsAdvanced(num = -1): Promise<ILogEntry[]> {
    const entries = await this._getEntries(num);
    return entries;
  }

  async downloadLogsAdvanced(num = -1): Promise<void> {
    const entries = await this.exportLogsAdvanced(num);
    const a = document.createElement("a");
    const file = new Blob([JSON.stringify(entries)], { type: "application/json" });

    a.href = URL.createObjectURL(file);
    a.download = `portal-advanced-log-${new Date().toISOString()}.json`;
    a.click();
  }

  /**
   * Clears all local logs
   */
  async clearLogs() {
    const tx = this._db.transaction(LOGS_STORE_NAME, "readwrite");
    const store = tx.objectStore(LOGS_STORE_NAME);

    await store.clear();
    await tx.done;
  }

  /*
   * Miscellaneous Helpers
   */

  /**
   * Gets the formatted name of the given `level`
   */
  getLevelName(level: ELogLevel): string {
    switch (level) {
      case ELogLevel.Error:
        return "ERROR";
      case ELogLevel.Warning:
        return "WARNING";
      case ELogLevel.Info:
        return "INFO";
      case ELogLevel.Debug:
        return "DEBUG";
      default:
        return "UNKNOWN";
    }
  }

  /**
   * Converts `obj` to a string
   */
  private _stringify(obj: any): string {
    if (typeof obj === 'bigint' || typeof obj === 'boolean' || typeof obj === 'number' || typeof obj === 'string' || typeof obj === 'undefined') {
      return `${obj}`;
    } else {
      if (obj instanceof Error) {
        if (obj.stack) {
          if (navigator.userAgent.toLowerCase().includes("firefox")) {
            return `${obj.name}: ${obj.message}\n${obj.stack}`;
          } else {
            return obj.stack;
          }
        } else {
          return `${obj.name}: ${obj.message}`;
        }
      } else {
        return JSON.stringify(obj);
      }
    }
  }

  /*
   * Browser Console Helpers
   */

  /**
   * Gets the proper console to output for a given `level`
   */
  private _getConsoleForLevel(level: ELogLevel): (...args: any[]) => void {
    switch (level) {
      case ELogLevel.Error:
        return (...args: any[]) => console.error(...args);
      case ELogLevel.Warning:
        return (...args: any[]) => console.warn(...args);
      case ELogLevel.Info:
        return (...args: any[]) => console.log(...args);
      case ELogLevel.Debug:
        return (...args: any[]) => console.debug(...args);
    }
  }

  /**
   * Gets the appropriate styling for a given `level`
   */
  private _getConsoleStyling(level: ELogLevel): string {
    switch (level) {
      case ELogLevel.Error:
      // return "font-weight: bold;";
      case ELogLevel.Warning:
      // return "font-weight: bold;";
      case ELogLevel.Info:
      // return "font-weight: bold; color: #BDBDBD";
      case ELogLevel.Debug:
        return "font-weight: bold; color: #BDBDBD;";
    }
  }

  /*
   * Database functions
   */

  /**
   * Initializes the database, and stores any pending logs
   */
  private async _initDatabase() {
    this._db = await openDB(DB_NAME, 1, {
      async upgrade(db, oldV, newV, tx) {
        if (oldV === 0) {
          await LoggingService.instance._defineDBSchema(db);
        } else {
          await LoggingService.instance._migrateDB(oldV, newV, db);
        }
      },
      blocked() { },
      blocking() { },
      terminated() { }
    });

    this._dbInit = true;

    // If any logs should have been stored, store them now
    if (this._preDbLogs.length > 0) {
      for (const entry of this._preDbLogs) {
        await this._storeEntry(entry);
      }

      this._preDbLogs = [];
    }
  }

  /**
   * Migrates from `oldVersion` to `newVersion`
   */
  private async _migrateDB(oldVersion: number, newVersion: number, db: IDBPDatabase) {
    // First version so no migrations exist
    LoggingService.instance.error(this.constructor.name, "Unknown database migration attempted from ", oldVersion, " to ", newVersion, ". Creating fresh database...");
    await this._defineDBSchema(db);
    return;
  }

  /**
   * Creates object stores for the database
   */
  private async _defineDBSchema(db: IDBPDatabase) {
    db.createObjectStore(LOGS_STORE_NAME, { autoIncrement: true });
    return;
  }

  /**
   * Stores the given `entry` in the database
   */
  private async _storeEntry(entry: ILogEntry) {
    const tx = this._db.transaction(LOGS_STORE_NAME, "readwrite");
    const store = tx.objectStore(LOGS_STORE_NAME);

    await store.add(entry);
    await this._trimDatabase(tx);
    await tx.done;
  }

  /**
   * Checks the size of the database and trims logs
   */
  private async _trimDatabase(tx: IDBPTransaction<unknown, ["logs"], "readwrite">) {
    const store = tx.objectStore(LOGS_STORE_NAME);
    const logCount = await store.count();

    if (logCount > MAX_LOGS) {
      const logsOver = logCount - MAX_LOGS; // Number of logs to remove
      const cursor = await store.openCursor();

      for (let i = 0; i < logsOver; i++) {
        await store.delete(cursor.key);
        await cursor.continue();
      }
    }

    // Do NOT finish the transaction
  }

  /**
   * Returns all stored log entries
   */
  private async _getEntries(num = -1): Promise<ILogEntry[]> {
    const tx = this._db.transaction(LOGS_STORE_NAME, "readonly");
    const store = tx.objectStore(LOGS_STORE_NAME);
    const entries = await store.getAll();
    await tx.done;

    if (num < 0) {
      return entries;
    } else {
      return entries.slice(0, num);
    }
  }
}
export enum ELogLevel {
    Error = 200,
    Warning = 300,
    Info = 400,
    Debug = 500,
}

export function getLogLevelName(level: ELogLevel): string {
    switch (level) {
        case ELogLevel.Error:
            return "Error";
        case ELogLevel.Warning:
            return 'Warning';
        case ELogLevel.Info:
            return 'Info';
        case ELogLevel.Debug:
            return 'Debug';
        default:
            return 'Unknown';
    }
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { UsersService } from '../api/users.service';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private _appData: AppDataService, private _router: Router, private _user: UsersService) { }

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    const returnUrl = _next.queryParams["returnUrl"] as string ?? "/user/home";
    if (this._appData.user) {
      return this._router.parseUrl(returnUrl);
    }

    if (!this._appData.user && this._appData.token && this._appData.token.length > 0) {
      // user has a token and no user, try to get the current user
      return this._user.getCurrentUser().pipe(catchError(err => {
        this._appData.clear();
        return of(true);
      }), map(user => {
        if (typeof user === "boolean") { return user; }
        
        if (user && user.userId > 0) {
          return this._router.parseUrl(returnUrl);
        }

        return true;
      }));
    }
    
    return true;
  }
}

import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private _logger: LoggingService) {

    }

    handleError(error: Error) {
        this._logger.error(`Global`, error.name, `: `, error.message, `, \n`, error.stack);
    }
}
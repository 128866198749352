import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { map, Observable } from "rxjs";
import { AppDataService } from "../app-data.service";

export interface ComponentCanDeactivate {
    canDeactivate(): boolean | Observable<boolean>;
}

export const CanDeactivateState = {
    defendAgainstBrowserBackButton: false
};

@Injectable({
    providedIn: "root"
})
export class UnloadGuard implements CanDeactivate<unknown> {
    constructor(private _appData: AppDataService) { }

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        const canDeactivate = component.canDeactivate ? component.canDeactivate() : null;

        if (!canDeactivate || typeof canDeactivate === "boolean") {
            if (!canDeactivate) {
                const confirmed = confirm("Are you sure you want to leave this page? Your session will be reset, and you will lose all data");
                if (!confirmed && CanDeactivateState.defendAgainstBrowserBackButton) {
                    history.pushState(null, "", "");
                }

                return confirmed;
            } else {
                return true;
            }
        } else {
            return canDeactivate.pipe(map(val => {
                if (val) {
                    return true;
                } else {
                    const confirmed = confirm("Are you sure you want to leave this page? Your session will be reset, and you will lose all data");
                    if (!confirmed && CanDeactivateState.defendAgainstBrowserBackButton) {
                        history.pushState(null, "", "");
                    }

                    return confirmed;
                }
            }));
        }
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { AppDataService } from '../app-data.service';
import { Department, DepartmentRank, IpsUser, Role, RoleGroup, User } from '../models';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpClient: HttpClient, private httpService: HttpService, private _appData: AppDataService) { }

  getDepartmentsAsync(showAllDepts: boolean): Observable<Department[]> {
    return this.httpService.sendGetAsync<Department[]>(`user/GetDepartmentsAsync/${showAllDepts}`).pipe(map(res => res.body));
  }

  getDepartmentRanksAsync(deptId: number, includeReserves: boolean): Observable<DepartmentRank[]> {
    return this.httpService.sendGetAsync<DepartmentRank[]>(`user/GetDepartmentRanksAsync/${deptId}/${includeReserves}`).pipe(map(res => res.body));
  }

  getRolesWithLevelAsync(highestLevel: number): Observable<RoleGroup[]> {
    return this.httpService.sendGetAsync<RoleGroup[]>(`user/GetRolesWithLevelAsync/${highestLevel}`).pipe(map(res => res.body));
  }

  addUserRoleAsync(roleId: number, userId: number): Observable<boolean> {
    return this.httpService.sendGetAsync(`user/role/${roleId}/${userId}`).pipe(map(res => res.ok));
  }

  removeUserRoleAsync(roleId: number, userId: number): Observable<boolean> {
    return this.httpService.sendDeleteAsync(`user/role/${roleId}/${userId}`).pipe(map(res => res.ok));
  }

  authenticateUserAsync(email: string, password: string): Observable<User> {
    const body = {
      "emailAddress": email,
      "password": password
    };

    return this.httpService.sendPostAsync<User>(`auth/login`, body).pipe(tap(res => {
      const token = res.headers.get("X-OSRP-Token");
      if (token != null) {
        this._appData.token = token;
      }
    }), map(data => data.body));
  }

  validateUserCredentialsAsync(email: string, password: string): Observable<boolean> {
    const body = {
      "emailAddress": email,
      "password": password
    };

    return this.httpService.sendPostAsync(`user/ValidateUserCredentials`, body).pipe(map(res => res.ok));
  }

  getCurrentUser(): Observable<User> {
    return this.httpService.sendGetAsync<User>(`user/current`).pipe(tap((res) => {
      if (res.status === 401) {
        this._appData.clear();
      } else {
        this._appData.user = res.body as User;
      }
    }), map(res => res.body));
  }

  loadForumDataAsync(websiteId: number): Observable<IpsUser> {
    return this.httpService.sendGetAsync<IpsUser>(`user/ips/${websiteId}`).pipe(map(res => res.body));
  }

  getUserAsync(id: number): Observable<User> {
    return this.httpService.sendGetAsync<User>(`user/GetUserAsync/${id}`).pipe(map(res => res.body));
  }

  getUsersAsync(searchTerm: string, includeDisabled: boolean, includeDeleted: boolean): Observable<User[]> {
    if (searchTerm) {
      return this.httpService.sendPostAsync<User[]>(`user/search/${includeDisabled}/${includeDeleted}`, { "data": searchTerm }).pipe(map(data => data.body));
    } else {
      return this.httpService.sendGetAsync<User[]>(`user/GetUsersAsync/${includeDisabled}/${includeDeleted}`).pipe(map(data => data.body));
    }
  }

  createUserAsync(user: User, password: string): Observable<User> {
    const body = { "user": user, "password": password };
    return this.httpService.sendPostAsync<User>(`user/CreateUserAsync`, body).pipe(map(res => res.body));
  }

  updateSelfUserAsync(user: User, password?: string): Observable<boolean> {
    const body = { "user": user, "password": password };
    return this.httpService.sendPutAsync(`user/self/${user.userId}`, body).pipe(map(res => res.ok));
  }

  updateUserAsync(user: User, password: string, updateDepts: boolean): Observable<User> {
    const body = { "user": user, "password": password };
    return this.httpService.sendPutAsync<User>(`user/UpdateUserAsync/${user.userId}/${updateDepts}`, body).pipe(map(res => res.body));
  }

  setWhitelistStateAsync(userId: number, whitelisted: boolean, env: string): Observable<boolean> {
    return this.httpService.sendGetAsync(`user/SetWhitelistState/${userId}/${whitelisted}/${env}`).pipe(map(res => res.ok));
  }

  triggerWhitelistUpdateAsync(): Observable<boolean> {
    return this.httpService.sendGetAsync(`user/TriggerWhitelistUpdateAsync`).pipe(map(res => res.ok));
  }
}

<ng-http-loader
  [debounceDelay]="300"
  [minDuration]="500"
  [backdropBackgroundColor]="'#000000'"
  [backgroundColor]="'#b8b9ba'"
  *ngIf="(isDesktop | async) === false"
  [filteredHeaders]="['X-OSRP-No-Spinner']">
</ng-http-loader>

<div class="content" [class.mat-typography]="(isDesktop | async) !== true">
  <router-outlet></router-outlet>
</div>

<!-- <osrp-footer></osrp-footer> -->
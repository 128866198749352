import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppDataService } from 'src/app/core/app-data.service';
import { LoggingService } from 'src/app/core/logging/logging.service';

@Component({
  selector: 'osrp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  showFooter = true;
  showAdvancedOptions = false;

  isTablet = false;

  private _destroyed$ = new Subject<void>();

  constructor(private _router: Router, private _appData: AppDataService, private _logger: LoggingService) { }

  ngOnInit() {
    this._router.events.pipe(takeUntil(this._destroyed$)).subscribe((event: RouterEvent) => {
      if (event && event instanceof NavigationEnd) {
        if (event.url && (event.url.includes("/desktop") || event.url.includes("/livemap"))) {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }

        if (event.url) {
          this.showAdvancedOptions = event.url.includes("settings");
        }

        this.isTablet = this._appData.isTablet;
      }
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  /**
   * Exports logs to a text format and initiates a download
   */
  async exportLogs() {
    const logs = await this._logger.exportLogs();
    const a = document.createElement("a");
    const file = new Blob([logs], { type: "text/plain" });
    a.href = URL.createObjectURL(file);
    a.download = `portal-log-${new Date().toISOString()}.txt`;
    a.click();
  }

  /**
   * Toggles if the portal is in tablet mode or not
   */
  toggleTabletMode() {
    this.isTablet = !this.isTablet;
    this._appData.isTablet = this.isTablet;

    // Reload to ensure that eeverything updates to reflect tablet mode being enabled/disabled
    location.reload();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {
  transform(input: string | Array<any>) {
    if (typeof input === "string") {
      return input.split("").reverse().join("");
    } else if (Array.isArray(input)) {
      return input.slice().reverse();
    } else {
      return input;
    }
  }

}

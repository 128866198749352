import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { DropdownDeptComponent } from './components/controls/dropdown-dept/dropdown-dept.component';
import { DropdownRankComponent } from './components/controls/dropdown-rank/dropdown-rank.component';
import { RouterModule } from '@angular/router';
import { FirstNamePipe } from './pipes/first-name.pipe';
import { LastUsedFrameworkPipe } from './pipes/last-used-framework.pipe';
import { LogNamePipe } from './pipes/log-name.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { PadPipe } from './pipes/pad.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { TruncatePipe } from './pipes/truncate.pipe';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { DefaultValuePipe } from './pipes/default-value.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    DropdownDeptComponent,
    DropdownRankComponent,
    FirstNamePipe,
    LogNamePipe,
    LastUsedFrameworkPipe,
    ReversePipe,
    PadPipe,
    TruncatePipe,
    LinkifyPipe,
    DefaultValuePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    DropdownDeptComponent,
    DropdownRankComponent,
    FirstNamePipe,
    LogNamePipe,
    ReversePipe,
    LastUsedFrameworkPipe,
    PadPipe,
    TruncatePipe,
    LinkifyPipe,
    DefaultValuePipe
  ]
})
export class SharedModule { }

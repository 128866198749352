import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppDataService } from '../app-data.service';
import { UserDepartment } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthCadGuard implements CanActivateChild {
  constructor(private _router: Router, private _appData: AppDataService) { }

  canActivateChild(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree {
    const user = this._appData.user;
    let cadDepts: UserDepartment[] = [];

    if (user.userDepartments && user.userDepartments.length > 0) {
        cadDepts = user.userDepartments.filter(x => x.department && x.department.isCadEnabled);
    }

    if (cadDepts && cadDepts.length > 0) {
        return true;
    } else {
        return this.redirectToHome(`You have no departments - contact your chain of command for assistance`);
    }
  }

  private redirectToHome(msg: string): UrlTree {
    alert(msg);
    return this._router.parseUrl(`/user/home`);
  }
}
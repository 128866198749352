import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Beat, EDepartment, EUnitClass, EUnitStatus, PatrolCall, PatrolCallLog, PatrolCallType, PatrolUnit, Server, ServerData } from '../models';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CadPatrolService {

  constructor(private httpService: HttpService) { }

  getBeats$(): Observable<Beat[]> {
    return this.httpService.sendGetAsync<Beat[]>(`patrol/beats`, false).pipe(map(res => res.body));
  }

  updateUnitBeat$(userId: number, beatId: number): Observable<boolean> {
    return this.httpService.sendPostAsync(`patrol/unit/beat/${userId}`, { "beatId": beatId }).pipe(map(res => res.ok));
  }

  getPatrolUnit$(userId: number): Observable<PatrolUnit> {
    return this.httpService.sendGetAsync<PatrolUnit>(`patrol/unit/${userId}`, false).pipe(map(res => res.body));
  }

  getUnitsInServer$(serverId: number): Observable<PatrolUnit[]> {
    return this.httpService.sendGetAsync<PatrolUnit[]>(`patrol/units/${serverId}`, false).pipe(map(res => res.body));
  }

  insertOrUpdatePatrolUnit$(patrolUnit: PatrolUnit): Observable<PatrolUnit> {
    return this.httpService.sendPostAsync<PatrolUnit>(`patrol/unit/save`, patrolUnit, false).pipe(map(res => res.body));
  }

  updateUnitIdentifier$(userId: number, identifier: string): Observable<boolean> {
    return this.httpService.sendPostAsync(`patrol/unit/identifier/${userId}`, { "identifier": identifier }).pipe(map(res => res.ok));
  }

  updateUnitStatus$(userId: number, status: EUnitStatus): Observable<boolean> {
    return this.httpService.sendGetAsync(`patrol/unit/status/${userId}/${status}`, false).pipe(map(res => res.ok));
  }

  updateUnitAssignment$(userId: number, callId: number | null): Observable<boolean> {
    return this.httpService.sendGetAsync(`patrol/unit/call/${userId}/${callId ?? 0}`, false).pipe(map(res => res.ok));
  }

  getCallsFromDates$(startDate: Date, endDate: Date, serverId: number): Observable<PatrolCall[]> {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);

    return this.httpService.sendGetAsync<PatrolCall[]>(`cad/calls/dates/${startDate.toISOString()}/${endDate.toISOString()}/${serverId}`, false).pipe(map(res => res.body));
  }

  getActiveCalls$(serverId: number): Observable<PatrolCall[]> {
    return this.httpService.sendGetAsync<PatrolCall[]>(`cad/calls/active/${serverId}`, false).pipe(map(res => res.body));
  }

  getCallById$(callId: number): Observable<PatrolCall> {
    return this.httpService.sendGetAsync<PatrolCall>(`cad/call/${callId}`).pipe(map(res => res.body));
  }

  createCall$(patrolCall: PatrolCall): Observable<PatrolCall> {
    return this.httpService.sendPostAsync<PatrolCall>(`cad/call`, patrolCall, false).pipe(map(res => res.body));
  }

  updateCall$(callId: number, patrolCall: PatrolCall): Observable<PatrolCall> {
    return this.httpService.sendPutAsync<PatrolCall>(`cad/call/${callId}`, patrolCall, false).pipe(map(res => res.body));
  }

  clearCall$(callId: number): Observable<boolean> {
    return this.httpService.sendGetAsync(`cad/call/${callId}/clear`, false).pipe(map(res => res.ok));
  }

  updateCallLog$(callId: number, message: string): Observable<PatrolCallLog> {
    return this.httpService.sendPostAsync<PatrolCallLog>(`cad/call/${callId}/callLog`, JSON.stringify(message)).pipe(map(res => res.body));
  }

  getCallTypes$(): Observable<PatrolCallType[]> {
    return this.httpService.sendGetAsync<PatrolCallType[]>(`cad/callTypes`, false).pipe(map(res => res.body));
  }

  getServersAsync(): Observable<Server[]> {
    return this.httpService.sendGetAsync<Server[]>(`patrol/servers`, false).pipe(map(res => res.body));
  }

  /**
   * Get servers with metadata
   */
  getServersWithDataAsync(): Observable<ServerData[]> {
    return this.httpService.sendGetAsync<ServerData[]>(`patrol/servers2`, false).pipe(map(res => res.body));
  }

  updateServer(value: Server): Observable<boolean> {
    return this.httpService.sendPostAsync(`patrol/server`, value).pipe(map(res => res.ok));
  }

  /**
   * Gets the unit class of a unit, which includes calcualting the class and storing it
   * into the PatrolUnit object if necessary
   */
  getUnitClass(unit: PatrolUnit): EUnitClass {
    if (!unit) return null;

    switch(unit.department.type) {
      case EUnitClass.Leo:
        unit.unitClass = EUnitClass.Leo;
        break;
      case EUnitClass.Fire:
        unit.unitClass = EUnitClass.Fire;
        break;
      case EUnitClass.Comms:
        unit.unitClass = EUnitClass.Comms;
        break;
      default:
        unit.unitClass = EUnitClass.Other;
    }

    return unit.unitClass;
  }

  isFireUnit(unit: PatrolUnit): boolean {
    return this.getUnitClass(unit) === EUnitClass.Fire;
  }

  isLeoUnit(unit: PatrolUnit): boolean {
    return this.getUnitClass(unit) === EUnitClass.Leo;
  }

  isCivUnit(unit: PatrolUnit): boolean {
    return this.getUnitClass(unit) === EUnitClass.Other;
  }

  isDispatchUnit(unit: PatrolUnit): boolean {
    return this.getUnitClass(unit) === EUnitClass.Comms;
  }
}

import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, map, Subject, takeUntil } from 'rxjs';
import { User, UserDepartment } from './models';
import { SettingsService } from './settings/settings.service';

const _cDefaultServerDisplay: boolean = false;
const _cDefaultCadTheme: string = "cad-dark-theme";

const CAD_THEME_KEY = "cadTheme";

@Injectable({
  providedIn: 'root'
})

export class AppDataService {
  private _userSrc$ = new BehaviorSubject<User | null>(null);
  user$ = this._userSrc$.asObservable();

  public cadTheme: string = this._settings.getSetting(CAD_THEME_KEY, _cDefaultCadTheme);
  public cadThemeObs: BehaviorSubject<string> = new BehaviorSubject<string>(_cDefaultCadTheme);

  public serverDisplay = _cDefaultServerDisplay;

  isUserAdmin$ = this.user$.pipe(map(() => this.isUserAdmin()));
  isUserStaff$ = this.user$.pipe(map(() => this.isUserStaff()));

  /**
   * The current authorization token
   */
  get token(): string | null {
    return localStorage.getItem("token");
  }

  set token(value: string | null) {
    if (value) {
      localStorage.setItem("token", value)
    } else {
      localStorage.removeItem("token");
    }
  }

  /**
   * The current user's information
   */
  get user(): User | null {
    return this._userSrc$.value;
  }

  set user(val: User | null) {
    this._userSrc$.next(val);
  }

  get primaryDepartment(): UserDepartment | null {
    return this.user.userDepartments.find(d => d.isPrimary);
  }

  /**
   * If the portal is currently in tablet mode
   */
  get isTablet(): boolean {
    return localStorage["isTablet"] === "true";
  }

  set isTablet(isTab: boolean) {
    localStorage["isTablet"] = isTab;
  }

  private _destroyed$ = new Subject<void>();

  constructor(private _router: Router, private _settings: SettingsService) {
    this._router.events.pipe(takeUntil(this._destroyed$)).subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        if (ev.url.includes("tablet=true")) {
          this.isTablet = true;
        }
      }
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  clear(): void {
    this.token = null;
    this.user = null;
  }

  isUserRoot(): boolean {
    return !!this.user?.roles?.find(d => d.roleGroup.name === "Root");
  }

  isUserAdmin(): boolean {
    return !!this.user?.roles?.find(d => d.roleGroup.name === "Admin" || this.isUserRoot());
  }

  isUserStaff(): boolean {
    return !!this.user?.roles?.find(d => d.roleGroup.name === "Staff" || this.isUserAdmin());
  }

  isUserLegalSupervisor(): boolean {
    return !!this.user?.roles?.find(d => d.roleGroup.name === "Legal Services Supervisor" || this.isUserAdmin());
  }

  isUserLegal(): boolean {
    return !!this.user?.roles?.find(d => d.roleGroup.name === "Legal Services" || this.isUserLegalSupervisor());
  }

  isUserDev(): boolean {
    return !!this.user?.roles?.find(d => d.roleGroup.name === "Developer" || this.isUserRoot());
  }

  /**
   * Toggles current CAD theme
   */
  toggleCadTheme(): void {
    if (this.cadTheme === "cad-light-theme") {
      this.setCadTheme("cad-dark-theme");
    } else {
      this.setCadTheme("cad-light-theme");
    }
  }

  /**
   * Sets the current CAD theme and serializes it to settings
   * @param name the theme to set
   */
  private setCadTheme(name: string) {
    this.cadTheme = name;
    this.cadThemeObs.next(name);
    // this._settings.setSetting(CAD_THEME_KEY, name);
  }

  getRoleChipColor(role: string): string {
    if (role === "Root") {
      return "primary";
    } else if (role === "Admin") {
      return "warn";
    } else if (role === "Staff") {
      return "accent";
    }

    return null;
  }
}
import { ELogLevel } from "./ELogLevel.enum";
import { LoggingService } from "./logging.service";

/**
 * Exposes methods to children that allow auto-fills the source field for any logging calls
 */
export class LoggingHelper {
    protected logError(...args: any[]) {
        this.log(ELogLevel.Error, ...args);
    }

    protected logWarning(...args: any[]) {
        this.log(ELogLevel.Warning, ...args);
    }

    protected logInfo(...args: any[]) {
        this.log(ELogLevel.Info, ...args);
    }

    protected logDebug(...args: any[]) {
        this.log(ELogLevel.Debug, ...args);
    }

    protected log(level: ELogLevel, ...args: any[]) {
        if (LoggingService.instance) {
            LoggingService.instance.log(this.constructor.name, level, ...args);
        } else if (!LoggingService.loggedInvalidInit) {
            LoggingService.loggedInvalidInit = true;
            console.error(`No active logging service! Failed to log: [${this.constructor.name}] [${level}]`, ...args);
            alert("The logging service did not properly initialize, reload the page to resolve the issue.");
        }
    }
}
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, distinctUntilChanged, finalize, map } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class SpinnerInterceptor implements HttpInterceptor {
    private pendingRequests$ = new BehaviorSubject<number>(0);
    showSpinner$ = this.pendingRequests$
        .asObservable()
        .pipe(map((r) => r > 0),
            distinctUntilChanged());

    constructor() { }

    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (req.headers.has("X-OSRP-No-Spinner")) {
            return next.handle(req);
        }

        this.pendingRequests$.next(this.pendingRequests$.value + 1);

        return next
            .handle(req)
            .pipe(
                finalize(() => {
                    this.pendingRequests$.next(this.pendingRequests$.value - 1)
                })
            );
    }
}
import { Pipe, PipeTransform } from "@angular/core";
import { LogLevel } from "src/app/core/models";

@Pipe({
    name: 'logName',
})
export class LogNamePipe implements PipeTransform {
    transform(value: LogLevel | string): string {
        switch (value) {
            case LogLevel.Error:
            case "500":
                return 'Error';
            case LogLevel.Warning:
            case "400":
                return 'Warning';
            case LogLevel.Info:
            case "300":
                return 'Info';
            case LogLevel.Debug:
            case "200":
                return 'Debug';
            default:
                return 'Unknown';
        }
    }
}
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'osrp-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  constructor(private _title: Title) {
    this._title.setTitle("OSRP Portal - 404");
  }
}
